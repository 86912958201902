import React, {useState, useEffect} from 'react';
import {Card, CardContent, Grid, Typography} from "@mui/material";
import {Player} from "@lottiefiles/react-lottie-player";
import {colors, Fonts} from "../common/theme.mjs";
import Spacer from "./Spacer";
import {useTranslation} from "react-i18next";

const AnimatedBanner = (props) => {
    const {t}=useTranslation();
    return (
        <Grid alignItems={'center'} justifyContent={'center'} container sx={{p:2,borderRadius:'16px',background:`linear-gradient(to right, #FFFFFF, ${colors.transparent})`,borderColor:colors.primary,borderWidth:'1px',borderStyle:'solid'}}>

                <Grid item xs={12} sm={12} md={5}>
                    <Player
                        autoplay
                        onEvent={event => {
                            if(event==='load'){
                            }
                        }}
                        loop={true}
                        src={'/images/comp.json'}
                        style={{ height: 'auto', width: '100%' }}
                    >
                    </Player>
                </Grid>

            <Grid item xs={12} sm={12} md={7}>
                <Typography sx={{fontSize:'30px',fontFamily:Fonts.AppExtraBold,color:colors.black}}>
                    {t('add_banner_title')}
                </Typography>
                <Spacer count={1}/>
                <Typography sx={{fontSize:'16px',fontFamily:Fonts.AppRegular,color:colors.black,width:'90%'}}>
                    {t('add_banner_desc')}
                </Typography>
            </Grid>

        </Grid>
    )
}

export default AnimatedBanner;