import AppButton from "./Button";
import React, {useContext, useState} from "react";
import {Box, Button, Grid, Modal, Skeleton, Stack, Typography} from "@mui/material";
import {colors, Fonts} from "../common/theme.mjs";
import {AppContext} from "../App";
import {useTranslation} from "react-i18next";
import CyrillicToLatin from "cyrillic-to-latin";
import Image from "mui-image";



const MedicineInfo = (props) => {
    const [open, setOpen] = useState(false);
    const handleOpen = () => {
        setOpen(true);
    }
    const handleClose = () => {
        setOpen(false);
    }
    const {isMobile,appLanguage} = useContext(AppContext);
    const {t}=useTranslation();
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: isMobile?'98%':'60%',
        bgcolor: 'background.paper',
        border: '0px solid transparent',
        boxShadow: 24,
        p: 4,
        overflowY:'scroll',
        height:'80%',
        display:'block',
        borderRadius:'22px'
    };
    return (
        <div>
            <AppButton style={{fontSize:isMobile?'10px':'14px'}} title={"Giňişleýin"} variant={'outlined'} onClick={handleOpen} />
            <Modal
                open={open}
                disableScrollLock={ true }
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} md={5}>
                            <Image
                                wrapperStyle={{width:'100%'}}
                                showLoading={<Skeleton sx={{width:'100%',height: isMobile?'200px':'500px'}} variant={'rounded'}/>}
                                src={props.item.image} alt={'medicine'} style={{width:'100%',height:isMobile?'200px':'500px',objectFit:'contain',borderRadius:'22px'}}/>
                        </Grid>
                        <Grid item xs={12} sm={12} md={7}>
                            <Stack spacing={2}>
                                <Stack direction={'row'} alignItems={'center'}>
                                    <Typography sx={{fontFamily:Fonts.AppSemiBold,fontSize:'18px',color:colors.black}}>{t('name')}</Typography>
                                    <Typography sx={{fontFamily:Fonts.AppRegular,fontSize:'14px',textTransform:'uppercase',ml:1,color:colors.black}}>
                                        {
                                            appLanguage==='ru'?props.item.name_ru:CyrillicToLatin(props.item[`name_${appLanguage}`])
                                        }
                                    </Typography>
                                </Stack>
                                <Stack direction={'column'} spacing={2}>
                                    <Typography sx={{fontFamily:Fonts.AppSemiBold,fontSize:'18px',color:colors.black}}>{t('desc')}</Typography>
                                    <Typography sx={{fontFamily:Fonts.AppRegular,fontStyle:'italic',fontSize:'14px',color:colors.black}}>
                                        {/*<pre style={{maxWidth:'100%',overflowX:'hidden'}}>*/}
                                        {props.item[`desc_${appLanguage}`]}
                                        {/*</pre>*/}
                                    </Typography>
                                </Stack>
                                <Stack direction={'row'} justifyContent={'center'} alignItems={'center'} sx={{width:'100%',mt:4}}>
                                    <Button onClick={handleClose} variant={'outlined'} color={'error'}>
                                        {t('back')}
                                    </Button>
                                </Stack>
                            </Stack>
                        </Grid>
                    </Grid>
                </Box>
            </Modal>
        </div>
    )
}

export default MedicineInfo