import i18n from "i18next";
import { initReactI18next } from "react-i18next";

const lorem='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.';

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const resources = {
    en: {
        translation: {
            "home_page": "Home page",
            "services":"Services",
            "about_us":"About us",
            "contact_us":"Contact us",
            "medicine":"Medicine",
            "work_time":"Opening hours: from 08:30 to 22:00",
            "about_medicine":"About the drug",
            "about_medicine_desc":"Experience shows that it is necessary to consult a doctor and carefully study the instructions for use of medicines before taking them.",
            "Giňişleýin":"Read more",
            "our_services":"Our services",
            "faq":"Frequently asked Questions",
            "see_answer":"See answer",
            "about_healthy":"Health Tips",
            "contact":"contact",
            "phone":"Phone number",
            "email":"Email the address",
            "address":"The address",
            "address_desc":"Asgabat st., street-1971 G. Ezizov (Sovhoznaya), street-25 Arzuv Halka",
            "gallery":"Gallery",
            "all_rights":"All rights reserved",

            // services
            "service-1":"Quality Medicines",
            "service-2":"Free shipping for hospital and healthcare",
            "service-3":"Use our credit card service with a 3% discount",
            "service-4":"Delivery service",

            // questions
            "question-1":"Is it safe to take expired medicines?",
            "answer-1":`The expiration date is the last day the manufacturer guarantees the full efficacy and safety of the drug. The drug expiration date is listed on the labels of most drugs, including prescription, over-the-counter (OTC) and dietary (herbal) supplements. US pharmaceutical manufacturers are required by law to provide an expiration date on prescription drugs before they go on the market.
For legal and liability reasons, manufacturers will not make recommendations about drug stability beyond the original expiration date.1 However, for most drugs, this is simply an arbitrary date, typically 1 to 5 years, that the manufacturer chooses to test drug stability. . Once the medicine container is opened after production, this expiration date is no longer guaranteed.`,
            "question-2":"How to deal with common drug side effects?",
            "answer-2":`Each of us is unique. However, certain individual factors make some of us more likely to develop side effects than others.

The most significant of these factors is age. The very young and very old are ALWAYS more susceptible to adverse reactions.

Children are not little adults. The way their bodies absorb, metabolize, and eliminate drugs is different from adults, and this is especially true for children. Young children tend to absorb the drug more slowly from the stomach, but have a higher rate of intramuscular (IM) absorption. When young, they have a higher ratio of water to lipids in the body and a larger ratio of liver to body weight. Liver enzymes are immature, as is their kidney function. In addition, they have a higher permeability of the blood-brain barrier (a layer of cells that limits the flow of substances from the bloodstream to the brain).

Older people tend to take more medication, and studies have shown that they are twice as likely to end up in the emergency room due to drug side effects and seven times as likely to be hospitalized. They are more likely to take drugs with a small gap between efficacy and toxicity, such as warfarin, insulin, digoxin, and anticonvulsants. Their bodies tend to have more fat and less water, which can increase the duration of some medications. In addition, hepatic metabolism and renal excretion are usually reduced. Their brains are also more sensitive to the sedative effects of drugs, and pre-existing problems such as dizziness, eye and ear problems may worsen, increasing the risk of falls.`,


            //health
            "health-1":"Cut down on sugar",
            "health-1-desc":`Sugar is not considered healthy food. Of course, you don't have to cut out sugar completely. But preference should be given to the right products for the body. These are, for example, sweet fruits that contain not only sugar, but also various vitamins necessary for the body.

A slice of cake served at a celebration certainly doesn't hurt. But it is better to forget about sweet, sweet bread and other overly sweet things. This also applies to the sugar you add to your tea or coffee. It will be better if you replace it with honey.`,
            "health-2":"Try to eat slowly during meals",
            "health-2-desc":`Try to have breakfast, lunch and dinner at least 20 minutes before. Do not forget that it is during this period that your body learns to absorb food, and at the same time your stomach feels full. In addition, eating slowly will help you maintain weight and get rid of extra pounds!`,
            "health-3":"Avoid junk food",
            "health-3-desc":`Fast food, sodas, candy bars and other foods do you no good. Therefore, it is necessary to abandon them forever, even if it will be difficult.`,
            "health-4":"Avoid salty foods",
            "health-4-desc":`Unfortunately, the diet of a modern person is characterized by excessive salt intake, which negatively affects health, which causes an increase in blood pressure, as well as the risk of various tumors. Scientists recommend reducing the amount of salt in food to a minimum.`,
            "health-5":"Walk as much as possible",
            "health-5-desc":`Walking in the fresh air will help you lose weight and stay healthy. Don't be lazy and try to take a walk if you can.`,
            "health-6":"Get enough sleep",
            "health-6-desc":`There is no doubt that people who sleep 6 hours or less a night have problems concentrating at work, they experience confusion, and neglecting sleep often leads to heart attacks and strokes. A person must sleep at least 7 hours to maintain health and beauty.`,


            // about
            "about-1":`Pharmacy "saglyk oty" was founded in 2019 and continues its activities to this day. The pharmacy is located in the city center, on G. Ezizov street. 1971/25.
The staff of the pharmacy is made up of qualified specialists (pharmacists, pharmacists) who can provide proper and timely advice to customers!`,
            "about-2":`Our main philosophy is people!
Our advantages:
- quality assurance for all categories of goods;
- cooperation with leading, reliable distributors;
- an extensive range (medicines, herbs, nursing, orthopedics and medical equipment, cosmetics, as well as goods for babies and mothers, etc.)
- Individual approach to every customer.`,
            "about-3":`The Saglyk Oty pharmacy regularly hosts promotions and discount cards, thanks to which our regular customers will be able to receive discounts on the entire range!`,
            "about-4":`Our main value is the Human! His health and mood. Every day we work so that as many people as possible in Turkmenistan can afford quality medicines, beauty and health products that will make life easier, happier and more enjoyable!`,


            // our services
            "about_services":"Service telephone 958499",

            // about us
            "about_apteka":`"Saglyk oty" pharmacy`,


            // contact us
            "contact_us_title":"Contact us",
            "contact_us_desc":`We ask that you enter your correct email address when sending an email.
A response to your email has been sent to the email address you provided.`,
            "send_paper":"Send us an email",
            "your_full_name":"Your full name...",
            "your_email":"Email the address...",
            "your_message":"Enter your message",
            "send":"Send",

            // medicine
            "name":"Name:",
            "desc":"Detailed information:",
            "back":"Back",

            "sent":"Successfully sent!",
            "add_banner_title":"HEALTH IS YOUR GREATEST WEALTH",
            "add_banner_desc":"There is no fortress that cannot be taken with health, no destination that cannot be reached. The root of everything is human health."
        }
    },
    ru: {
        translation: {
            "home_page": "Главная",
            "services":"Услуги",
            "about_us":"О нас",
            "contact_us":"Свяжитесь с нами",
            "medicine":"Лекарство",
            "work_time":"Время работы: с 08:30 до 22:00",
            "about_medicine":"О препарате",
            "about_medicine_desc":"Опыт показывает, что необходимо проконсультироваться с врачом и внимательно изучить инструкцию по применению лекарственных средств перед их приемом.",
            "Giňişleýin":"Читать больше",
            "our_services":"Наши услуги",
            "faq":"Часто задаваемые вопросы",
            "see_answer":"См. ответ",
            "about_healthy":"Полезные советы для здоровья",
            "contact":"контакт",
            "phone":"Номер телефона",
            "email":"Эл. адрес",
            "address":"Адрес",
            "address_desc":"ул. Ашхабадская, ул.-1971 Г.Эзизова (Совхозная), ул.-25 Арзув Халка",
            "gallery":"Галерея",
            "all_rights":"Все права защищены",

            // services
            "service-1":"Качественные лекарства",
            "service-2":"Бесплатная доставка для больницы и здравоохранения",
            "service-3":"Воспользуйтесь нашим сервисом кредитных карт со скидкой 3%",
            "service-4":"Служба доставки",

            // questions
            "question-1":"Безопасно ли принимать просроченные лекарства?",
            "answer-1":`Срок годности — это последний день, когда производитель гарантирует полную эффективность и безопасность лекарства. Срок годности лекарств указан на этикетках большинства лекарств, включая рецептурные, безрецептурные (OTC) и диетические (травяные) добавки. Американские фармацевтические производители по закону обязаны указывать срок годности рецептурных препаратов до их выхода на рынок.

По юридическим причинам и причинам ответственности производители не будут давать рекомендации относительно стабильности лекарств по истечении первоначального срока годности.1 Однако для большинства лекарств это просто произвольная дата, обычно от 1 до 5 лет, которую производитель выбирает для проверки стабильности лекарства. . Как только контейнер с лекарством открывается после производства, этот срок годности больше не гарантируется.`,
            "question-2":"Как справиться с распространенными побочными эффектами лекарств?",
            "answer-2":`Каждый из нас уникален. Однако определенные индивидуальные факторы делают некоторых из нас более склонными к развитию побочных эффектов, чем других.

Наиболее значимым из этих факторов является возраст. Очень молодые и очень старые ВСЕГДА более восприимчивы к нежелательным реакциям.

Дети – это не маленькие взрослые. То, как их тела поглощают, метаболизируют и выводят наркотики, отличается от взрослых, и это особенно верно для детей. Дети младшего возраста, как правило, медленнее всасывают лекарство из желудка, но имеют более высокую скорость внутримышечного (ВМ) всасывания. В молодости у них более высокое соотношение воды и липидов в организме и большее отношение печени к массе тела. Ферменты печени незрелые, как и их функция почек. Кроме того, у них выше проницаемость гематоэнцефалического барьера (слоя клеток, ограничивающего поступление веществ из кровотока в головной мозг).

Пожилые люди, как правило, принимают больше лекарств, и исследования показали, что у них в два раза больше шансов попасть в отделение неотложной помощи из-за побочных эффектов лекарств и в семь раз больше шансов быть госпитализированными. Они с большей вероятностью будут принимать лекарства с небольшим разрывом между эффективностью и токсичностью, такие как варфарин, инсулин, дигоксин и противосудорожные препараты. Их тела, как правило, имеют больше жира и меньше воды, что может увеличить продолжительность действия некоторых лекарств. Кроме того, метаболизм в печени и выведение через почки обычно снижены. Их мозг также более чувствителен к седативному действию лекарств, а ранее существовавшие проблемы, такие как головокружение, проблемы с глазами и ушами, могут усугубляться, увеличивая риск падений.`,


            //health
            "health-1":"Сократите потребление сахара",
            "health-1-desc":`Сахар нельзя считать здоровой пищей. Конечно, полностью отказываться от сахара не нужно. Но предпочтение следует отдавать правильным продуктам для тела. Это, например, сладкие фрукты, которые содержат не только сахар, но и различные необходимые организму витамины.

Кусочек торта, поданный на торжестве, уж точно не повредит. А вот о сладком, сладком хлебе и других чересчур сладких вещах лучше забыть. Это также относится к сахару, который вы добавляете в чай ​​или кофе. Будет лучше, если вы замените его медом.`,
            "health-2":"Старайтесь есть медленно во время еды",
            "health-2-desc":`Старайтесь завтракать, обедать и ужинать не менее чем за 20 минут. Не забывайте, что именно в этот период ваш организм учится усваивать пищу, и в то же время ваш желудок чувствует себя сытым. Кроме того, медленная еда поможет вам сохранить вес и избавиться от лишних килограммов!`,
            "health-3":"Избегайте нездоровой пищи",
            "health-3-desc":`Фаст-фуд, газированные напитки, шоколадные батончики и другие продукты не приносят вам никакой пользы. Поэтому необходимо отказаться от них навсегда, даже если это будет трудно.`,
            "health-4":"Избегайте соленой пищи",
            "health-4-desc":`К сожалению, рацион современного человека отличается чрезмерным употреблением соли, что негативно сказывается на здоровье, что является причиной повышения артериального давления, а также риска возникновения различных опухолей. Ученые рекомендуют свести количество соли в пище к минимуму.`,
            "health-5":"Ходите как можно больше",
            "health-5-desc":`Прогулки на свежем воздухе помогут похудеть и сохранить здоровье. Не ленитесь и попробуйте прогуляться, если у вас есть возможность.`,
            "health-6":"Выспаться",
            "health-6-desc":`Нет сомнений в том, что люди, которые спят по 6 и менее часов в сутки, имеют проблемы с концентрацией внимания на работе, у них наблюдается спутанность сознания, а пренебрежение сном часто приводит к сердечным приступам и инсультам. Человек должен спать не менее 7 часов, чтобы сохранить здоровье и красоту.`,


            // about
            "about-1":`Аптека « саглык оты» основана в 2019 году и продолжает свою деятельность по сей день. Аптека располагается в центре города, на улице Г. Эзизов.1971/25.
Штат аптеки составляют квалифицированные специалисты (провизоры, фармацевты), которые смогут оказать должную и своевременную консультацию покупателям!`,
            "about-2":`Наша главная философия - это люди!
Наши достоинства:
- гарантия качества на все категории товаров;
-сотрудничество с ведущими, надежными дистрибьюторами;
-обширный ассортимент (лекарственные средства, травы, уход за больными, ортопедия и медицинская техника, косметические средства, а также товары для малышей и матерей и пр.)
- индивидуальный подход к каждому покупателю.`,
            "about-3":`В аптеке « Саглык оты» регулярно проходят акции и действуют скидочные карты, благодаря этому наши постоянные покупатели смогут получать скидки  на весь ассортимент!`,
            "about-4":`Основная наша ценность - это Человек! Его самочувствие и настроение. Каждый день мы работаем для того чтобы как можно больше людей в Туркменистане могли позволить себе качественные лекарства, товары для красоты и здоровья, которые сделают жизнь легче, радостнее и приятнее!`,


            // our services
            "about_services":"Телефон сервисной службы 958499",

            // about us
            "about_apteka":`"Saglyk oty" аптека`,


            // contact us
            "contact_us_title":"Свяжитесь с нами",
            "contact_us_desc":`Мы просим вас правильно вводить адрес электронной почты при отправке электронного письма
Ответ на ваше письмо отправлен на указанный адрес электронной почты`,
            "send_paper":"Отправить нам письмо",
            "your_full_name":"Ваше полное имя...",
            "your_email":"Эл. адрес...",
            "your_message":"Введите сообщение",
            "send":"Отправить",

            // medicine
            "name":"Имя:",
            "desc":"Подробная информация:",
            "back":"Назад",

            "sent":"Успешно отправлено!",
            "add_banner_title":"ЗДОРОВЬЕ - ВАШЕ БОЛЬШОЕ БОГАТСТВО",
            "add_banner_desc":"Нет крепости, которую нельзя взять со здоровьем, нет пункта назначения, куда нельзя добраться. Корень всего – здоровье человека."
        }
    },
    tm: {
        translation: {
            "home_page": "Baş sahypa",
            "services":"Hyzmatlar",
            "about_us":"Biz barada",
            "contact_us":"Biz bilen habarlaşyň",
            "medicine":"Dermanlar",
            "work_time":"Iş wagty: 08: 30-dan 22: 00-a çenli",
            "about_medicine":"Dermanlar barada",
            "about_medicine_desc":"Tejribe, lukman bilen maslahatlaşmagyň we dermanlary kabul etmezden ozal ulanmak boýunça görkezmeleri üns bilen öwrenmegiň zerurdygyny görkezýär.",
            "Giňişleýin":"Doly oka",
            "our_services":"Hyzmatlarymyz",
            "faq":"Köp soralýan soraglar",
            "see_answer":"Jogaba serediň",
            "about_healthy":"Saglyk barada maslahatlar",
            "contact":"Kontakt",
            "phone":"Telefon belgisi",
            "email":"Email",
            "address":"Salgy",
            "address_desc":"Asgabat g., köce-1971 G.Ezizow (Sowhoznaya), jaÿ-25 Arzuw Halka",
            "gallery":"Galereýa",
            "all_rights":"Ähli hukuklar goragly",

            // services
            "service-1":"Ýokary hilli dermanlar",
            "service-2":"Asgabatdaky Hassahana we Saglyk öyleri üçin eltip bermek mugt ",
            "service-3":"Kredit kartymyzy 3% arzanladyş bilen ulanyň",
            "service-4":"Eltip bermek hyzmaty",

            // questions
            "question-1":"Möhleti geçen dermanlary kabul etmek ygtybarlymy?",
            "answer-1":`Möhleti gutarýan senesi, öndürijiniň dermanyň doly netijeliligini we howpsuzlygyny kepillendirýän iň soňky güni. Derman möhletiniň gutarýan senesi, dermanlaryň köpüsiniň ýazgylarynda, şol sanda resept, artykmaç (OTC) we berhiz (ösümlik) goşundylarynda görkezilýär. 

Kanuny we jogapkärçilik sebäplerinde öndürijiler derman serişdeleriniň durnuklylygy barada möhletiniň gutarýan senesinden artyk maslahat bermeýärler.1 Şeýle-de bolsa, derman serişdeleriniň köpüsi üçin öndürijiniň derman durnuklylygyny barlamak üçin saýlan karary, adatça 1 ýyldan 5 ýyla çenli. . Derman konteýni önümçilikden soň açylansoň, bu möhleti kepillendirilmeýär.`,
            "question-2":"Umumy derman serişdelerine nähili täsir etmeli?",
            "answer-2":`Biziň her birimiz özboluşly. Şeýle-de bolsa, käbir aýry-aýry faktorlar käbirimize beýlekilerden has oňaýsyz täsirleri döredýär.

Bu faktorlaryň iň möhümi ýaşdyr. Örän ýaş we garrylar hemişe ýaramaz täsirlere has ýykgyn edýärler.

Çagalar uly adamlar däl. Bedeniniň derman serişdelerini siňdirmek, metabolizasiýa etmek we ýok etmek usuly ululardan tapawutlanýar we bu esasanam çagalar üçin has dogrydyr. Childrenaş çagalar dermanlary aşgazandan has haýal siňdirýärler, ýöne öýjükli (IM) siňdiriş derejesi has ýokary. Youngaş wagty suwuň bedende lipidlere has köp gatnaşygy we bagryň beden agramyna has uly gatnaşygy bolýar. Bagyr fermentleri, böwrekleriň işleýşi ýaly ýetişmedi. Mundan başga-da, olaryň gan-beýni barýeriniň has ýokary geçirijiligi bar (ganyň beýnisine maddalaryň akymyny çäklendirýän öýjükler gatlagy).

Garry adamlar has köp derman içmäge ýykgyn edýärler we gözlegler derman serişdeleriniň täsiri sebäpli gyssagly kömek otagynda iki esse we hassahana ýerleşdirilmeginiň ýedi esse ýokarydygyny görkezdi. Warfarin, insulin, digoksin we antikonwulsanlar ýaly täsirlilik bilen zäherliligiň arasynda azajyk boşluk bolan dermanlary kabul etmek has ähtimal. Bedenlerinde has köp ýag we suw az bolýar, bu bolsa käbir dermanlaryň dowamlylygyny artdyryp biler. Mundan başga-da, bagryň alyş-çalşy we böwrekleriň bölünip çykmagy azalýar. Beýnileri derman serişdeleriniň sedatiw täsirlerine has duýgur we baş aýlanmak, göz we gulak ýaly problemalar hasam erbetleşip, ýykylmak howpuny ýokarlandyryp biler.`,


            //health
            "health-1":"Şekeri ulanmagy azaldyň",
            "health-1-desc":`Şekeri sagdyn iýmit diýip hasaplamak bolmaz. Elbetde, şekerden doly ýüz öwürmelem däl. Emma beden üçin zerur hem dogry önümlere artykmaçlyk berilmeli. Bular, mysal üçin, süýji miwelerdir, olarda diňe şeker däl, bedene gerekli dürli witaminler hem köp.

Baýramçylykda hödürlenen bir bölek tort, elbetde, size zyýan etmez. Emma süýjüleri, şirin nanlary we beýleki aşa süýji zatlary unutmak has gowusydyr. Bu siziň çaýyňyza ýa-da kofäňize goşýan şekerdir gandyňyza hem degişlidir. Ony bal bilen çalyşsaňyz has ýaramly bolar.`,
            "health-2":"Naharlanan mahalyňyz haýal iýmäge çalşyň",
            "health-2-desc":`Azyndan 20 minutda ertirlik, günortanlyk we agşamlyk naharyny edinmegi düzgüne saljak boluň. Hut şu döwürde siziň bedeniňiz iýmiti özleşdirmäge ýetişýändigini unutmaň, şol wagtda-da aşgazan doýandygyny duýýar. Mundan başga-da, nahary haýal iýmek agramy durnuklaşdyrmaga we goşmaça kilogramlardan dynmaga kömek eder!`,
            "health-3":"Gerek däl iýmitlerden ýüz öwrüň",
            "health-3-desc":`Fast-fud, gazly suw, şokolad batonjyklary we beýleki önümler size hiç hili peýda getirmeýär. Şonuň üçin olardan, kyn hem bolsa, hemişelik ýüz öwürmeli.`,
            "health-4":"Duzly iýmitlerden gaça duruň",
            "health-4-desc":`Gynansak-da, häzirki zaman adamynyň iýmitinde saglyga ýaramaz täsir edýän duz artykmaç ulanylýar, bu bolsa gan basyşynyň ýokarlanmasynyň, şeýle-de dürli çişleriň döreme töwekgelçiliginiň sebäbidir. Alymlar iýmitdäki duzuň mukdaryny iň az derejä çenli azaltmagy maslahat berýärler.`,
            "health-5":"Mümkin boldugyça köpräk pyýada ýöräň",
            "health-5-desc":`Arassa howada gezelenç etmek horlanmaga we sagdyn bolmaga kömek eder. Ýaltanmaň we mümkinçilik boldugy, pyýada gezjek boluň.`,
            "health-6":"Ukyny ýeterlik alyň",
            "health-6-desc":`Günde 6 sagat ýa-da ondanam az uklaýan adamlaryň işde ünsi jemlemekde kynçylyk çekýändikleri şübhesizdir, olarda pikiriň dagynyklygy ýüze çykýar, üstesine-de, uka äsgermezlik edilmegi, köplenç, ýürek agyrylaryna we insultlara sebäp bolýar. Saglygy we gözelligi saklamak üçin adam azyndan 7 sagat uklamalydyr.`,


            // about
            "about-1":`"Saglyk oty" dermanhanasy 2019-njy ýylda esaslandyryldy we şu güne çenli işini dowam etdirýär. Dermanhana şäher merkezinde, G. Ezizow köçesinde ýerleşýär. 1971/25.
Dermanhananyň işgärleri müşderilere dogry we öz wagtynda maslahat berip biljek ökde hünärmenlerden (farmasewtler) ybarat!`,
            "about-2":`Esasy pelsepämiz adamlar!
Üstünliklerimiz:
- harytlaryň ähli kategoriýalary üçin hiliň kepili;
- öňdebaryjy, ygtybarly paýlaýjylar bilen hyzmatdaşlyk;
- giň gerim (dermanlar, otlar, şepagat uýalary, ortopediýa we lukmançylyk enjamlary, kosmetika, şeýle hem bäbekler we eneler üçin harytlar we ş.m.)
- Her bir müşderä aýratyn çemeleşmek.`,
            "about-3":`“Saglyk Oty” dermanhanasynda mahabat we arzanladyş kartlary yzygiderli geçirilýär, munuň netijesinde yzygiderli müşderilerimiz ähli ugurda arzanladyş alyp bilerler!`,
            "about-4":`Esasy gymmatlygymyz Adam we onuň Saglygy!. Türkmenistanda mümkin boldugyça köp adamyň durmuşy aňsat, bagtly we lezzetli etjek ýokary hilli dermanlary, gözelligi we saglyk önümlerini alyp bilmekleri üçin her gün işleýäris!`,


            // our services
            "about_services":"Hyzmat telefony 958499",

            // about us
            "about_apteka":`"Saglyk oty" dermanhanasy`,


            // contact us
            "contact_us_title":"Biz bilen habarlaşmak",
            "contact_us_desc":`E-poçta ibereniňizde dogry e-poçta salgyňyzy ýazmagyňyzy haýyş edýäris.
E-poçtaňyza jogap, beren e-poçta salgyňyza iberildi.`,
            "send_paper":"Bize hat ugradyň",
            "your_full_name":"Doly adyňyz ...",
            "your_email":"Email...",
            "your_message":"Hatyňyzy giriziň",
            "send":"Ugrat",

            // medicine
            "name":"Ady:",
            "desc":"Giňişleýin maglumat:",
            "back":"Yza dolan",

            "sent":"Üstünlikli iberildi!",

            "add_banner_title":"SAGLYK IŇ ULY BAÝLYKDYR",
            "add_banner_desc":"Saglyk bilen alynmajak gala, ýetilmejek menzil ýokdur. Ähli zadyň sakasy adamyň jan saglygydyr."
        }
    }
};

i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources,
        lng: "ru", // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
        // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
        // if you're using a language detector, do not define the lng option

        interpolation: {
            escapeValue: false // react already safes from xss
        }
    });

export default i18n;