export const contact={
    phone:["958499","958798","140303"],
    email:"saglykoty@gmail.com",
    address:"address_desc",
    imo:"+99361724466",
    icq:"icq:saglyk_oty",
    instagram:"https://www.instagram.com/saglyk_oty_apteka/"
}
export const banners = [
    {
        src: "/images/banner/IMG_6571.jpg",
        link: "/",
        mobile:"/images/banner/IMG_6571.jpg",
        hash:"LaNKS3_N9FRi9YE0M{NGbtD%NGRj",
    },
    {
        src: "/images/banner/IMG_6572.jpg",
        link: "/",
        mobile:"/images/banner/IMG_6572.jpg",
        hash:"LaNKS3_N9FRi9YE0M{NGbtD%NGRj",
    },
    {
        src: "/images/banner/IMG_6480.jpg",
        link: "/",
        mobile:"/images/banner/IMG_6480.jpg",
        hash:"LaNKS3_N9FRi9YE0M{NGbtD%NGRj",
    },
    {
        src: "/images/banner/IMG_6479.jpg",
        link: "/",
        mobile:"/images/banner/IMG_6479.jpg",
        hash:"LaNKS3_N9FRi9YE0M{NGbtD%NGRj",
    },
    {
        src: "/images/banner/zero.png",
        link: "/",
        mobile:"/images/banner/zero_m.png",
        hash:"LaNKS3_N9FRi9YE0M{NGbtD%NGRj",
    },
    {
        src: "/images/banner/one.png",
        link: "/",
        mobile:"/images/banner/one_m.png",
        hash:"PSQA28~WD%IV^*Dl-.of?HE1t6xZNLxVWGjb%2NGxakCV@ozV[bE",
    },
    {
        src: "/images/banner/two.png",
        link: "/",
        mobile:"/images/banner/two_m.png",
        hash:"LCN^;m00OcR;000Ji]%4_4I-0J%4",
    }
];

export const services = [
    {
        title:"service-1",
        icon:'/images/drug.png',
        desc:"service-1"
    },
    {
        title:"service-2",
        icon:'/images/hospital.png',
        desc:"service-2"
    },
    {
        title:"service-3",
        icon:'/images/discount.png',
        desc:"service-3"
    },
    {
        title:"service-4",
        icon:'/images/delivery.webp',
        desc:"service-4"
    }
];

export const healthInfo = [
    {
        title:"health-1",
        desc:"health-1-desc",
        image:"/images/health/1.webp"
    },
    {
        title:"health-2",
        desc:"health-2-desc",
        image:"/images/health/2.webp"
    },
    {
        title:"health-3",
        desc:"health-3-desc",
        image:"/images/health/3.webp"
    },
    {
        title:"health-4",
        desc:"health-4-desc",
        image:"/images/health/4.webp"
    },
    {
        title:"health-5",
        desc:"health-5-desc",
        image:"/images/health/5.webp"
    },
    {
        title:"health-6",
        desc:"health-6-desc",
        image:"/images/health/6.webp"
    }
]