import React, {useState, useEffect, useRef, useContext} from "react";
import {Blurhash} from "react-blurhash";
import {AppContext} from "../App";
import ImageListItem from "@mui/material/ImageListItem";

function srcset(image, size, rows = 1, cols = 1) {
    return {
        src: `${image}?w=${size * cols}&h=${size * rows}&fit=crop&auto=format`,
        srcSet: `${image}?w=${size * cols}&h=${size * rows
        }&fit=crop&auto=format&dpr=2 2x`,
    };
}

function LazyImage(props) {
    const [loaded, setLoaded] = useState(false);
    const {isMobile}=useContext(AppContext);
    return (
        <ImageListItem key={props.item.img} cols={props.item.cols || 1} rows={props.item.rows || 1}>
                <img
                    {...srcset(`/gallery/${props.item.img}`, isMobile ? 121 : 221, props.item.rows, props.item.cols)}
                    alt={props.item.title}
                    loading="lazy"
                    style={{visibility:loaded?'visible':'hidden'}}
                    onLoad={() => setLoaded(true)}
                />
                <Blurhash
                    style={{display:loaded?'none':'block',height:isMobile ? 121 : 221*props.item.rows,width:'100%'}}
                    hash="eCF6B#-:0JInxr?@s;nmIoWUIko1%NocRk.8xbIUaxR*^+s;RiWAWU"
                />
        </ImageListItem>
    );
}

function MainLazyImage(props) {
    const [loaded, setLoaded] = useState(false);
    const {isMobile}=useContext(AppContext);
    return (
        <div>
            <img
                {...srcset(`/gallery/${props.item.img}`, isMobile ? 121 : 221, props.item.rows, props.item.cols)}
                alt={props.item.title}
                loading="lazy"
                style={{visibility:loaded?'visible':'hidden'}}
                onLoad={() => setLoaded(true)}
            />
            <Blurhash
                style={{display:loaded?'none':'block',height:isMobile ? 121 : 221*props.item.rows,width:'100%'}}
                hash="eCF6B#-:0JInxr?@s;nmIoWUIko1%NocRk.8xbIUaxR*^+s;RiWAWU"
            />
        </div>
    );
}
export default LazyImage;